var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"form"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('p',{staticClass:"font-bold"},[_vm._v("Si usted es representante de una empresa del exterior, por favor agregue la empresa e indique el país de la casa matriz y el nombre de la empresa.")]),_vm._l((_vm.model.representedCompanies),function(company,index){return _c('div',{key:index,staticClass:"row"},[_c('div',{staticClass:"col-8 col-md-5 pt-0 pb-0"},[_c('validation-provider',{attrs:{"name":"Empresa","vid":"company","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","outlined":"","dense":"","error-messages":errors,"label":"Empresa","required":""},model:{value:(company.name),callback:function ($$v) {_vm.$set(company, "name", $$v)},expression:"company.name"}})]}}],null,true)})],1),_c('div',{staticClass:"col-8 col-md-5 pt-0 pb-0"},[_c('validation-provider',{attrs:{"vid":"countries","name":"País","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.countries,"item-text":"name","item-value":"id","outlined":"","dense":"","label":"País*"},model:{value:(company.countryId),callback:function ($$v) {_vm.$set(company, "countryId", $$v)},expression:"company.countryId"}})]}}],null,true)})],1),_c('div',{staticClass:"col-4 col-md-2 pt-0 pb-0 pr-1"},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.deleteRepresentedCompany(index)}}},[_c('v-icon',{attrs:{"center":"","dark":"","size":"15"}},[_vm._v(" fa-trash ")])],1)],1)])}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 pt-0"},[_c('v-btn',{staticClass:"mr-2 text--primary text-none elevation-0",attrs:{"color":"secondary"},on:{"click":_vm.addBrand}},[_vm._v(" Agregar ")])],1)])],2)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 pt-0 pb-0"},[_c('h3',[_vm._v("Perfil de su cliente potencial*")]),_c('v-container',{attrs:{"fluid":""}},[_c('validation-provider',{attrs:{"vid":"potentialCustomerProfile","name":"Perfil de su cliente potencial","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"counter":"","outlined":"","clearable":"","type":"text","error-messages":errors,"clear-icon":"mdi-close-circle"},model:{value:(_vm.model.potentialCustomerProfile),callback:function ($$v) {_vm.$set(_vm.model, "potentialCustomerProfile", $$v)},expression:"model.potentialCustomerProfile"}})]}}])})],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 pt-0 pb-0"},[_c('p',{staticClass:"body-2"},[_vm._v("Indiquemos si usted tiene una recomendacion o solicitud especial para la rueda de negocios.")]),_c('h3',[_vm._v("Comentarios")]),_c('v-container',{attrs:{"fluid":""}},[_c('validation-provider',{attrs:{"vid":"englishReview","name":"Comentarios"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"counter":"","outlined":"","clearable":"","type":"text","error-messages":errors,"clear-icon":"mdi-close-circle"},model:{value:(_vm.model.commentsToBusinessConference),callback:function ($$v) {_vm.$set(_vm.model, "commentsToBusinessConference", $$v)},expression:"model.commentsToBusinessConference"}})]}}])})],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 pt-0 pb-0"},[_c('v-btn',{staticClass:"mr-2 text-none elevation-0",attrs:{"color":"primary"},on:{"click":_vm.saveStep9}},[(!_vm.hasPermission('EDIT_BOOKING'))?_c('span',[_vm._v("Siguiente")]):_vm._e(),(_vm.hasPermission('EDIT_BOOKING'))?_c('span',[_vm._v("Guardar")]):_vm._e()]),_c('v-btn',{staticClass:"mr-2 text-none elevation-0",attrs:{"color":"default"},on:{"click":function($event){return _vm.$emit('nextStep',8)}}},[_vm._v(" Anterior ")])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }