<template>
  <div>
    <validation-observer ref="form">
      <div class="row">
        <div class="col-12 pt-0 pb-0" v-if="selectedStands.length">
          <stands-table :selected-stands="selectedStands" :show-values="true"
                        :cop-payment-currency="copPaymentCurrency"/>
        </div>
        <div class="col-12 col-md-6">
          <h3>Método de pago:</h3>
          <p>{{ model.paymentMethodName }}</p>
          <h3 class="mt-2">Valor de las cuotas</h3>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left">
                  Cuota #
                </th>
                <th class="text-left">
                  Porcentaje
                </th>
                <th class="text-left">
                  Valor
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(plan, index) in model.paymentPlans" :key="plan.id">
                <td>{{ index + 1 }}</td>
                <td>{{ plan.percentage * 100 }}%</td>
                <td>
                  {{ copPaymentCurrency ? '$' : '' }}
                  {{ plan.percentage * totalPrice | currency }}
                  {{ !copPaymentCurrency ? 'USD' : '' }}
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </div>
      <div class="col-12 ml-2 pt-0 pb-0">
        <ValidationProvider v-slot="{ errors }" name="Términos y condiciones de uso de la información"
                            :rules="{ required: { allowFalse: false }}"
        >
          <v-checkbox v-model="model.acceptTerms" :error-messages="errors"
                      :rules="[v => v || 'Debes aceptar los términos y condiciones de uso de la información para continuar']">
            <div slot='label'>
              Acepta los
              <a @click.stop href="https://fise.co/wp-content/uploads/2020/06/habeas-data-terminos-de-informacion.pdf"
                 target="_blank">
                Términos y condiciones de uso de la información.
              </a>
            </div>
          </v-checkbox>
        </ValidationProvider>
      </div>
      <div class="col-12 ml-2 pt-0 pb-0">
        <ValidationProvider v-slot="{ errors }" name="Reglamento del expositor"
                            :rules="{ required: { allowFalse: false }}">
          <v-checkbox v-model="model.acceptExpositorTerms" :error-messages="errors"
                      :rules="[v => v || 'Debes aceptar el reglamento del expositor para continuar']">
            <div slot='label'>
              Acepta
              <a @click.stop href="https://fise.co/wp-content/uploads/2023/01/Manual_del_expositor_10a_Feria_FISE.pdf"
                 target="_blank">
                Reglamento del expositor
              </a>
            </div>
          </v-checkbox>
        </ValidationProvider>
      </div>
      <div class="col-12 ml-2 pt-0 pb-0">
        <ValidationProvider v-slot="{ errors }" name="Términos contractuales"
                            :rules="{ required: { allowFalse: false }}">
          <v-checkbox v-model="model.acceptUseTerms" :error-messages="errors"
                      :rules="[v => v || 'Debes aceptar los términos contractuales para continuar']">
            <div slot='label'>
              Acepta los
              <a @click.stop
                 href="https://fise.co/wp-content/uploads/2023/01/TERMINOS_CONTRACTUALES_RESERVA_10a_FERIA-FISE.pdf"
                 target="_blank">
                Términos contractuales.
              </a>
            </div>
          </v-checkbox>
        </ValidationProvider>
      </div>
      <div class="row mt-8">
        <div class="col-12">
          <v-btn color="primary" class="mr-2 text-none elevation-0" @click="saveStep7">
            <span v-if="!hasPermission('EDIT_BOOKING')">Siguiente</span>
            <span v-if="hasPermission('EDIT_BOOKING')">Guardar</span>
          </v-btn>
          <v-btn color="default" class="mr-2 text-none elevation-0" @click="$emit('nextStep',6)">
            Anterior
          </v-btn>
        </div>
      </div>
    </validation-observer>
  </div>
</template>

<script>

import { BOOKINGS_URL } from '@/constants/ServicesConstants'
import loaderMixin from '@/mixins/loaderMixin'
import notificationMixin from '@/mixins/notificationMixin'
import crudServiceMixin from '@/mixins/crudServiceMixin'
import formOperationsMixin from '@/mixins/formOperationsMixin'
import eventHub from '@/eventhub'
import StandsTable from '@/views/public/booking/components/StandsTable'
import sessionMixin from '@/mixins/sessionMixin'

export default {
  components: {
    StandsTable
  },
  computed: {
    totalPrice () {
      let totalPrice = 0
      this.selectedStands.forEach((stand) => {
        if (this.copPaymentCurrency) {
          totalPrice += stand.copValue
          totalPrice += stand.copTax
        } else {
          totalPrice += stand.usdValue
          totalPrice += stand.usdTax
        }
      })
      return totalPrice
    }
  },
  created () {
    eventHub.$on('step7', async () => {
      try {
        this.showLoader()
        await this.getStep7()
        this.hideLoader()
      } catch {
        this.hideLoader()
        this.showError()
      }
    })
  },
  data () {
    return {
      model: {
        acceptExpositorTerms: false,
        acceptTerms: false,
        acceptUseTerms: false,
        paymentMethodName: null,
        paymentPlans: []
      },
      copPaymentCurrency: true,
      selectedStands: []
    }
  },
  methods: {
    async getStep7 () {
      const response = await this.get(BOOKINGS_URL + '/' + this.bookingId + '/confirm-step-7')
      this.model.acceptTerms = (response.data.company.acceptTerms === 1)
      this.model.acceptExpositorTerms = (response.data.company.acceptExpositorTerms === 1)
      this.model.acceptUseTerms = (response.data.company.acceptUseTerms === 1)
      this.selectedStands = response.data.stands
      this.copPaymentCurrency = response.data.company.countryName === 'Colombia'
      this.model.paymentMethodName = response.data.company.paymentMethodName
      this.model.paymentPlans = response.data.paymentPlans
    },
    saveStep7 () {
      this.executeWithFormValidation(() => {
        this.create(BOOKINGS_URL + '/' + this.bookingId + '/confirm-step-7', this.model).then(() => {
          this.$emit('nextStep', 8)
        })
      })
    }
  },
  mixins: [loaderMixin, notificationMixin, crudServiceMixin, formOperationsMixin, sessionMixin],
  name: 'BookingSummary',
  props: {
    bookingId: {
      default: null,
      type: String
    }
  }
}
</script>
