<template>
  <div>
    <validation-observer ref="form">

      <div class="row">
        <div class="col-12">
          <p class="font-bold">Si usted es representante de una empresa del exterior, por favor agregue la empresa e
            indique el país de la casa matriz y el nombre de la empresa.</p>
          <div class="row" v-for="(company, index) in model.representedCompanies" :key="index">
            <div class="col-8 col-md-5 pt-0 pb-0">
              <validation-provider
                v-slot="{ errors }"
                name="Empresa"
                vid="company"
                rules="required|max:255"
              >
                <v-text-field
                  v-model="company.name"
                  type="text"
                  outlined
                  dense
                  :error-messages="errors"
                  label="Empresa"
                  required
                ></v-text-field>
              </validation-provider>
            </div>
            <div class="col-8 col-md-5 pt-0 pb-0">
              <validation-provider vid="countries" v-slot="{ errors }" name="País" rules="required">
                <v-select
                  v-model="company.countryId"
                  :error-messages="errors"
                  :items="countries"
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                  label="País*"
                ></v-select>
              </validation-provider>
            </div>
            <div class="col-4 col-md-2 pt-0 pb-0 pr-1">
              <v-btn color="primary" class="mr-2" @click="deleteRepresentedCompany(index)">
                <v-icon
                  center
                  dark
                  size="15">
                  fa-trash
                </v-icon>
              </v-btn>
            </div>
          </div>
          <div class="row">
            <div class="col-12 pt-0">
              <v-btn color="secondary" class="mr-2 text--primary text-none elevation-0" @click="addBrand">
                Agregar
              </v-btn>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 pt-0 pb-0">
          <h3>Perfil de su cliente potencial*</h3>
          <v-container fluid>
            <validation-provider
              vid="potentialCustomerProfile"
              v-slot="{ errors }"
              name="Perfil de su cliente potencial"
              rules="required"
            >
              <v-textarea
                counter
                outlined
                clearable
                type="text"
                :error-messages="errors"
                clear-icon="mdi-close-circle"
                v-model="model.potentialCustomerProfile"
              ></v-textarea>
            </validation-provider>
          </v-container>
        </div>
      </div>
      <div class="row">
        <div class="col-12 pt-0 pb-0">
          <p class="body-2">Indiquemos si usted tiene una recomendacion o solicitud especial para la rueda de
            negocios.</p>
          <h3>Comentarios</h3>
          <v-container fluid>
            <validation-provider
              vid="englishReview"
              v-slot="{ errors }"
              name="Comentarios"
            >
              <v-textarea
                counter
                outlined
                clearable
                type="text"
                :error-messages="errors"
                clear-icon="mdi-close-circle"
                v-model="model.commentsToBusinessConference"
              ></v-textarea>
            </validation-provider>
          </v-container>
        </div>
      </div>
      <div class="row">
        <div class="col-12 pt-0 pb-0">
          <v-btn color="primary" class="mr-2 text-none elevation-0" @click="saveStep9">
            <span v-if="!hasPermission('EDIT_BOOKING')">Siguiente</span>
            <span v-if="hasPermission('EDIT_BOOKING')">Guardar</span>
          </v-btn>
          <v-btn color="default" class="mr-2 text-none elevation-0" @click="$emit('nextStep',8)">
            Anterior
          </v-btn>
        </div>
      </div>
    </validation-observer>
  </div>
</template>

<script>

import { BOOKINGS_URL, ADDRESSES_URL } from '@/constants/ServicesConstants'
import loaderMixin from '@/mixins/loaderMixin'
import notificationMixin from '@/mixins/notificationMixin'
import crudServiceMixin from '@/mixins/crudServiceMixin'
import formOperationsMixin from '@/mixins/formOperationsMixin'
import eventHub from '@/eventhub'
import sessionMixin from '@/mixins/sessionMixin'

export default {
  created () {
    eventHub.$on('step9', async () => {
      try {
        this.showLoader()
        await this.getStep9()
        await this.loadCountries()
        this.hideLoader()
      } catch {
        this.hideLoader()
        this.showError()
      }
    })
  },
  data () {
    return {
      countries: [],
      model: {
        potentialCustomerProfile: '',
        commentsToBusinessConference: '',
        representedCompanies: []
      }
    }
  },
  methods: {
    addBrand () {
      this.model.representedCompanies.push({})
    },
    deleteRepresentedCompany (position) {
      this.model.representedCompanies = this.model.representedCompanies.filter((item, index) => index !== position)
    },
    async loadCountries () {
      const response = await this.get(ADDRESSES_URL + '/countries')
      this.countries = response.data
    },
    async getStep9 () {
      const response = await this.get(BOOKINGS_URL + '/' + this.bookingId + '/confirm-step-9')
      this.model.potentialCustomerProfile = response.data.potentialCustomerProfile
      this.model.commentsToBusinessConference = response.data.commentsToBusinessConference
      this.model.representedCompanies = (response.data.represented_companies.length > 0) ? response.data.represented_companies.map(item => {
        return {
          countryId: item.countryId,
          name: item.name
        }
      }) : []
    },
    saveStep9 () {
      this.executeWithFormValidation(() => {
        this.create(BOOKINGS_URL + '/' + this.bookingId + '/confirm-step-9', this.model).then(() => {
          this.$emit('nextStep', 10)
        })
      })
    }
  },
  mixins: [loaderMixin, notificationMixin, crudServiceMixin, formOperationsMixin, sessionMixin],
  name: 'RepresentedCompanies',
  props: {
    bookingId: {
      default: null,
      type: String
    }
  }
}
</script>
